<template>
    <div class="init">
        <el-form :model="fromValue" label-width="auto"  ref='form_' class="fromClass" label-position="top" :rules='rules'>
            <el-form-item :label="$t('currencySet.name1')"  v-if='isPlatform' prop="tenantId">
                <el-select v-model="fromValue.tenantId" :placeholder="$t('inputTit.xuanze')" @change="getItem" :teleported='false'>
                    <el-option :label="item.name" :value="item.id" v-for='item in tenantArray' :key = 'item.id'/>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('currencySet.name2')" prop="appId">
                <el-select v-model="fromValue.appId" :placeholder="$t('inputTit.xuanze')" :teleported='false'>
                    <el-option :label="item.appName" :value="item.appId" v-for='item in appArray' :key = 'item.appId'  />
                </el-select>
            </el-form-item>
            <el-form-item label="作品简称" prop="collectionName">
                <el-input v-model="fromValue.itemName" disabled :placeholder="$t('inputTit.xuanze')">
                    <template #append v-if='fromValue.tenantId && fromValue.appId'>
                        <el-button text type="primary" @click="chooseCoin">{{$t('button.checks')}}</el-button>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item label="发送设置" prop="receiveList">
                <property-view ref='propertyView_'/>
            </el-form-item>
             <el-form-item label="发送时间" prop="batchSendTime">
                <el-date-picker v-model="fromValue.batchSendTime" type="datetime" :disabled-date="disabledDate"
                    placeholder="选择时间" :disabled-hours="disabledHours" :disabled-minutes="disabledMinutes" :default-time='defaultTime' />
            </el-form-item>
        </el-form>
         <el-dialog v-model="innerVisible1" width="60%" title="作品选择" append-to-body>
            <collectionView @getCoin1='getCoin1' ref='collectionView_'/>
        </el-dialog>
         <el-dialog v-model="innerVisible2" width="400" title="发送确认" append-to-body>
            <div>
                <div class="codeClass">你将向{{fromValue.receiveList.length}}个地址发送{{_i}}个NFT，请确保填写的地址准确无误，应为填错地址后，NFT将无法找回。<br />请注意，本次交易的链费已设置为默认标准，如果你填写的信息有误，请返回上一步进行修改</div>
                <div>
                    Google 验证码：<el-input v-model="googleCode" placeholder="请填写" />
                </div>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="innerVisible2 = false">上一步</el-button>
                    <el-button type="primary" @click="completeFun">确认发送</el-button>
                </div>
            </template>

        </el-dialog>
    </div>
</template>
<script setup>
    import { ref,reactive,nextTick,defineEmits,getCurrentInstance,defineExpose } from 'vue'
    import { userStore } from '@/store/user'
    import collectionView from './collection.vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    const { $t } = getCurrentInstance().proxy;
    import apiList from '@/const/apiList';
    import { randomCoding } from '@/utils/util.js'
    import propertyView from './property.vue'
    
    const fromValue = reactive({ 
        tenantId:'',appId: '',collectionName:'',batchSendTime:'',
        receiveList:[],chain:'',protocol:'',contractAddress:'',fromAddress:'',
        fromWalletId:'',transactionId: '',
        applyUserType:2,applyUser:'',itemName:''
    })
    const tokenId_ = ref('')
    const num_ = ref(0)

    const emit = defineEmits(['successFun'])
    const tenantArray = ref([])
    const appArray = ref([]); 
    apiList.getTenantList((data)=>{ tenantArray.value = data })
    apiList.getAppList((data)=>{appArray.value = data;},fromValue.tenantId) 

    const innerVisible1 = ref(false)
    const innerVisible2 = ref(false)
    const form_ = ref(null)
    const rules = ref(null); rules.value = addRules.mainRules
    const googleCode = ref(null)
    const propertyView_ = ref(null)
    const _i = ref( 0 )
    const collectionView_ = ref(null)
    const isPlatform = ref(null);isPlatform.value = userStore().getIsPlatform


    const disabledDate = (e)=>{
        return e.getTime() < new Date().getTime() - 24 * 3600 * 1000
    }
    const disabledHours = ()=>{//获得当前小时的范围
        let array_ = []
        for(let i =  0;i < new Date().getHours();++ i){
            array_.push(i)
        }
        if(new Date().getMinutes() > 49){
            array_.push(new Date().getHours())
        }
        return array_
    }
    const disabledMinutes = (hour)=>{//获得当前分钟的
        let array_ = []
        if(hour == new Date().getHours()){
            for(let i =  0;i < new Date().getMinutes() + 10;++ i){
                array_.push(i)
            }
        }
        if(hour == new Date().getHours()+ 1 && new Date().getMinutes() > 49 ){
            for(let i =  0;i < 60 - new Date().getMinutes();++ i){
                array_.push(i)
            }
        }
        return array_
    }
    const defaultTime = new Date(new Date().getTime() + 10 * 60 + 1000)
    const chooseCoin = ()=>{
        innerVisible1.value = true
        nextTick(()=>{
            collectionView_.value.init_({
                tenantId: fromValue.tenantId,
                appId: fromValue.appId,
                protocol: 'ERC1155',
                nftOwnerType: 2
            })
        })
    }
    const getItem = ()=>{//选项框的联动设置
        fromValue.appId = ''
        apiList.getAppList((data)=>{appArray.value = data;},fromValue.tenantId)
    }
    const completeFun = ()=>{
        if(googleCode.value){
            fromValue.transactionId = randomCoding(64)
            Api_.addSendNftList(fromValue,googleCode.value).then(res=>{
                innerVisible2.value = false
                if(res.code === 0){
                    emit('successFun')
                    ElNotification({
                        message: $t('alert.succAdd'),
                        type: 'success',
                    })
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
            })
        }else{
            ElNotification({
                message: '填写Google code',
                type: 'error',
            })
        }
    }
    const nextFun = ()=>{
        let array_ = propertyView_.value.getTableData()
        fromValue.receiveList = array_
        if (!form_.value) return
        form_.value.validate((e) => {
            if(e){
                _i.value = 0
                for(let item of array_){
                    item.tokenId = tokenId_.value
                    _i.value = parseInt(_i.value) + parseInt(item.amount)
                }
                if(_i.value > num_.value){
                    ElNotification({
                        message: '超出最大数量',
                        type: 'error',
                    })
                    return 0
                }
                innerVisible2.value = true
            }
        })
    }
    const init_ = ()=>{
        for(let index in fromValue){
            fromValue[index] = ''
        }
        fromValue.applyUserType = 2
        fromValue.applyUser = userStore().getUserInfo[0].username;
        fromValue.tenantId = userStore().getUserInfo[0].tenantId;
    }
    defineExpose({nextFun,init_})
    const getCoin1 = (e)=>{
        innerVisible1.value = false
        fromValue.collectionName = e.collectionName
        fromValue.itemName = e.itemName
        fromValue.chain = e.chain
        fromValue.protocol = e.protocol
        fromValue.contractAddress = e.contractAddress
        fromValue.fromAddress = e.nftOwnerAddr
        fromValue.fromWalletId = e.ownnerWalletId
        tokenId_.value = e.tokenId
        num_.value = e.totalNum
    }
</script>
<style scoped lang="scss" >
    .codeClass{
        margin-bottom: 10px;
        font-size: 10px;
        color: red;
    }
</style>